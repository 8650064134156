import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/seo/SEO';
import Hero from '../components/Hero';
import Resort from '../components/Resort';
import ContactButton from '../components/ContactButton';
import useI18n from '../utils/useI18n';

const Blacklist = ({
  pageContext: { switchLang },
  data: {
    hotels: { nodes: records },
    blacklist: { html: BlacklistHtml },
  },
  location,
}) => {
  const { t } = useI18n();
  return (
    <Layout switchLang={switchLang}>
      <SEO
        title={t('blacklist.meta.title')}
        altTitle={t('menu.blacklist')}
        description={t('blacklist.meta.description')}
        keywords={t('blacklist.meta.keywords')}
        pathname={location.pathname}
        webpage
      />
      <Hero
        title={t('blacklist.hero.title')}
        callout={t('blacklist.hero.callout')}
        secondary
        extra={
          <div
            dangerouslySetInnerHTML={{
              __html: BlacklistHtml,
            }}
          />
        }
      />
      <div className="c-section o-wrapper u-padding-bottom-tiny">
        <ul className="c-resorts o-layout">
          {records.map((record) => (
            <li className="o-layout__item u-3/12@wide u-4/12@desktop u-6/12@tablet" key={record}>
              <Resort record={record} />
            </li>
          ))}
        </ul>
      </div>
      <ContactButton section />
    </Layout>
  );
};

export const hotelsListQuery = graphql`
  query hotelsListQuery {
    hotels: allHotel {
      nodes {
        Hotel {
          id
          slug
          titulo
          comments
        }
        featuredImg {
          childImageSharp {
            fluid(maxWidth: 600, maxHeight: 338, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    blacklist: htmlFragment(name: { eq: "blacklist" }) {
      html
    }
  }
`;

export default Blacklist;
